<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input matInput [formControl]="$any(ngControl.control)" />
  <button
    matSuffix
    type="button"
    mat-icon-button
    [disabled]="ngControl.control?.disabled"
    (click)="getAsyncOptions()"
  >
    <mat-icon>launch</mat-icon>
  </button>
  <mat-error *ngIf="ngControl.control?.errors?.['required']"
    >Please enter a {{ label }}</mat-error
  >
</mat-form-field>
