import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Self, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'verosoft-design-button-input',
  templateUrl: './button-input.component.html',
  styleUrls: ['./button-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonInputComponent implements ControlValueAccessor, OnDestroy {
  /**
   * Input  of button input component
   */
  @Input() label = '';

  /**
   * Input field type (number, text, email, etc)
   */
  @Input() type = 'text';

  /**
   * The unique identifier of the option list to set as the form value.
   */
  @Input() optionId = '';

  /**
   * List of async options to query when the search button is clicked.
   */
  @Input() optionsAsync: Observable<Record<string, any> | undefined> | undefined;

  private subscription: Subscription | undefined;

  constructor(@Self() public ngControl: NgControl, private cd: ChangeDetectorRef) {
    this.ngControl.valueAccessor = this;
  }

  /**
   * On destroy life cycle hook, used to managed subcriptions life cycle.
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**
   * Gets async options and set the selected field value.
   */
  getAsyncOptions(): void {
    this.subscription = this.optionsAsync?.pipe(take(1)).subscribe((option: Record<string, any> | undefined) => {
      if (!option) return;
      this.ngControl.control?.setValue(option[this.optionId]);
      this.ngControl.control?.markAsDirty();
      this.cd.markForCheck();
    });
  }

  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}
}
