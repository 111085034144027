/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { BryntumSchedulerProComponent } from '@bryntum/schedulerpro-angular';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import type { WorkOrder, WorkOrderLine } from '@tag/graphql';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

import { Priority } from '@models/priority';
import { AppointmentReleasedWorkOrderLine } from '@schedule/appointment-released-work-order-line';
import { ScheduleDetail } from '@stores-models/schedule-detail';
import { TimeSheet } from '@stores-models/timesheet';
import { AuthState } from '@stores-states/authentication.state';
import { EventModel, SchedulerResourceModel } from '@bryntum/schedulerpro';
import { PermissionsService } from '@shared-services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulerUtilitiesService {
  priorities: Priority[] = [
    {
      text: 'Critical',
      id: 1,
      color: '#DE8B94',
    },

    {
      text: 'Emergency',
      id: 2,
      color: '#EC9D7F',
    },
    {
      text: 'Urgent',
      id: 3,
      color: '#F3BF5D',
    },
    {
      text: 'Routine',
      id: 4,
      color: '#9CAFD1',
    },
    {
      text: 'Special Attention',
      id: 5,
      color: '#C1A5CD',
    },
    {
      text: 'Deferred',
      id: 6,
      color: '#B5B7BB',
    },
  ];

  constructor(
    private translocoService: TranslocoService,
    private readonly store: Store,
    private readonly permissionsService: PermissionsService
  ) {}

  /* Utilities */

  /**
   * Adds minutes
   *
   * @param date
   * @param minutes
   * @returns date of minutes
   */
  addMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() + minutes * 60000);
  }

  /**
   * Gets priority color
   *
   * @param priority
   * @returns priority color
   */
  getPriorityColor(priority: string): EventModel['eventColor'] {
    switch (priority) {
      case 'critical':
        return 'red';
      case 'urgent':
        return 'yellow';
      case 'routine':
        return 'blue';
      case 'emergency':
        return 'orange';
      case 'deferred':
        return 'gray';
      default:
        return 'green';
    }
  }

  /**
   * Gets combined date and time
   *
   * @param date
   * @param time
   * @returns date of combined date and time
   */
  getCombinedDateAndTime(date: Date, time: string): Date | undefined {
    const myDate = new Date(date);
    const myDateString =
      '' +
      myDate.getFullYear() +
      '-' +
      (myDate.getMonth() + 1) +
      '-' +
      myDate.getDate();
    if (myDateString === '1-1-1') return undefined;

    return new Date(myDateString + ' ' + time);
  }

  /**
   * Gets pointer date time
   *
   * @param x
   * @returns pointer date time
   */
  getPointerDateTime(
    x: number,
    scheduler?: BryntumSchedulerProComponent
  ): Date | undefined {
    if (scheduler) {
      const date = scheduler.instance.getDateFromCoordinate(x, 'round', false);
      return date;
    }
    return;
  }

  /**
   * Gets pointer resource
   *
   * @param element
   * @returns pointer resource
   */
  getPointerResource(
    element: HTMLElement,
    scheduler?: BryntumSchedulerProComponent
  ): SchedulerResourceModel | undefined {
    if (scheduler) {
      const resource = scheduler.instance.resolveResourceRecord(element);
      return resource;
    }
    return;
  }

  /**
   * Gets time from date
   *
   * @param date
   * @returns time from date
   */
  getTimeFromDate(date: Date): string {
    const currentDate = new Date(date);
    const paddingHours = this.getPaddingNumberAsString(currentDate.getHours());
    const paddingMinutes = this.getPaddingNumberAsString(
      currentDate.getMinutes()
    );
    return `${paddingHours}:${paddingMinutes}:00`;
  }

  /**
   * Gets padding number as string
   *
   * @param d
   * @returns padding number as string
   */
  getPaddingNumberAsString(d: number): string {
    return d < 10 ? '0' + d.toString() : d.toString();
  }

  /**
   * Gets Released Work Order Line ending date
   *
   * @param rwol Work Order Line object
   * @returns date of rwol ending date
   */
  getRwolEndingDate(rwol: WorkOrderLine): Date | undefined {
    const endDate = this.getRwolStartingDate(rwol);
    if (!endDate) return;

    const isTimeSheet = this.store.selectSnapshot(
      AuthState.userInfo
    )?.useConsumeTimesheet;
    const time = isTimeSheet
      ? rwol.actualTime || 0.25
      : rwol.estimatedTime || 0.25;
    endDate.setHours(endDate.getHours() + time, (time % 1) * 60);

    return endDate;
  }

  /**
   * Gets rwol starting date
   *
   * @param rwol
   * @returns date of rwol starting date
   */
  getRwolStartingDate(rwol: WorkOrderLine): Date | undefined {
    if (!rwol.startingDatetime) {
      console.error(
        'Wol does not have a valid starting Date.' +
          rwol.workOrderNo +
          ', ' +
          rwol.lineNo
      );
      return;
    }

    return new Date(rwol.startingDatetime);
  }

  /**
   * Gets priority id
   *
   * @param value
   * @returns priority id
   */
  getPriorityId(value: any): number {
    let priorityId = -1;
    switch (value) {
      case 'Critical':
        priorityId = 1;
        break;
      case 'Emergency':
        priorityId = 2;
        break;
      case 'Urgent':
        priorityId = 3;
        break;
      case 'Routine':
        priorityId = 4;
        break;
      case 'Special Attention':
        priorityId = 5;
        break;
      case 'Deferred':
        priorityId = 6;
        break;
      default:
        priorityId = 0;
    }
    return priorityId;
  }

  /**
   * Times difference
   *
   * @param current
   * @param previous
   * @returns difference
   */
  timeDifference(current: Date, previous: Date): string {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current.valueOf() - previous.valueOf();

    if (elapsed < msPerMinute) {
      return this.translocoService.translate('aFewSecondsAgoKey');
    } else if (elapsed < msPerHour) {
      return this.translocoService.translate('minutesAgoKeyParam', {
        minutes: Math.round(elapsed / msPerMinute),
      });
    } else if (elapsed < msPerDay) {
      return this.translocoService.translate('hoursAgoKeyParam', {
        hours: Math.round(elapsed / msPerHour),
      });
    } else if (elapsed < msPerMonth) {
      return this.translocoService.translate('approximatelyXDaysAgoKeyParam', {
        days: Math.round(elapsed / msPerDay),
      });
    } else if (elapsed < msPerYear) {
      return this.translocoService.translate(
        'approximatelyXMonthsAgoKeyParam',
        {
          months: Math.round(elapsed / msPerMonth),
        }
      );
    } else {
      return this.translocoService.translate('approximatelyXYearsAgoKeyParam', {
        years: Math.round(elapsed / msPerYear),
      });
    }
  }

  /**
   * Maps time sheet appointment
   *
   * @param timeSheetLines
   * @param workOrderLines
   * @returns time sheet appointment
   */
  mapTimeSheetAppointment(
    timeSheetLines: TimeSheet[],
    workOrderLines: WorkOrderLine[] = [],
    workOrders: WorkOrder[] = []
  ): AppointmentReleasedWorkOrderLine[] {
    return timeSheetLines
      .map((tsl: TimeSheet): AppointmentReleasedWorkOrderLine => {
        const isAllDayAppointment = tsl.actualTime === 0;
        const wol = workOrderLines.find(
          (_wol) =>
            _wol.workOrderNo + _wol.lineNo ===
            tsl.sourceWorkOrderNo + tsl.sourceWorkOrderLine
        );
        const wo = workOrders.find(
          (_wo) => _wo.workOrderNo === tsl.sourceWorkOrderNo
        );

        return {
          uid: tsl.id,
          releasedWorkOrderLine: Object.assign({}, wol),
          timeSheetLine: Object.assign({}, tsl),
          appointmentStartDate: tsl.startingDatetime,
          appointmentEndDate: tsl.endingDatetime,
          isAllDayAppointment,
          priorityId: this.getPriorityId(wol?.priority || wo?.priority),
          text:
            tsl.description + (tsl.equipmentId ? ' - ' + tsl.equipmentId : ''),
          type: 'booking',
          technicianCode: tsl.technicianCode || '',
          disabled:
            tsl.posted ||
            ['Approved', 'Pending', 'hrApproved', 'hrPosted'].includes(
              tsl.approvalStatus
            ),
        };
      })
      .filter(
        (item) =>
          new Date(item?.appointmentStartDate) !==
          new Date('1/1/0001 12:00:00 AM')
      );
  }

  /**
   * Maps work order lines appointment
   *
   * @param workOrderLines
   * @returns work order lines appointment
   */
  mapWorkOrderLinesAppointment(
    workOrderLines: WorkOrderLine[]
  ): AppointmentReleasedWorkOrderLine[] {
    return workOrderLines
      .filter(
        (item) => item.workOrderNo && item.lineNo && item.startingDatetime
      )
      .map((rwol: WorkOrderLine): AppointmentReleasedWorkOrderLine => {
        let appointmentWol = {} as AppointmentReleasedWorkOrderLine;

        appointmentWol = {
          uid: rwol.workOrderNo + rwol.lineNo,
          releasedWorkOrderLine: Object.assign({}, rwol),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          appointmentStartDate: rwol.startingDatetime
            ? new Date(rwol.startingDatetime)
            : this.getRwolStartingDate(rwol) ?? new Date(),
          appointmentEndDate: rwol.endingDatetime
            ? new Date(rwol.endingDatetime)
            : this.getRwolEndingDate(rwol) ?? new Date(),
          isAllDayAppointment: false,
          priorityId: this.getPriorityId(rwol.priority),
          type: 'wol',
          text: rwol.description + ' - ' + rwol.equipmentDescription,
          technicianCode: rwol.technicianCode || '',
          disabled:
            this.permissionsService.canEditWorkOrderLine(rwol)() === false,
        };
        return appointmentWol;
      });
  }

  /**
   * Maps employee schedule appointment
   *
   * @param scheduleEvents
   * @returns employee schedule appointment
   */
  mapEmployeeScheduleAppointment(
    scheduleEvents: ScheduleDetail[]
  ): AppointmentReleasedWorkOrderLine[] {
    return scheduleEvents.map(
      (scheduleEvent: ScheduleDetail): AppointmentReleasedWorkOrderLine => {
        let appointmentWol = {} as AppointmentReleasedWorkOrderLine;
        if (
          scheduleEvent.scheduledStartDateTime &&
          scheduleEvent.scheduledEndDateTime &&
          scheduleEvent.resourceNo
        )
          appointmentWol = {
            uid: scheduleEvent.id,
            appointmentStartDate: new Date(
              scheduleEvent.scheduledStartDateTime.toString()
            ),
            appointmentEndDate: new Date(
              scheduleEvent.scheduledEndDateTime.toString()
            ),
            employeeSchedule: scheduleEvent,
            isAllDayAppointment: false,
            priorityId: 0,
            type: 'schedule',
            text:
              scheduleEvent.timeEntryType + ' - ' + scheduleEvent.timeEntryType,
            technicianCode: scheduleEvent.resourceNo,
            disabled: scheduleEvent.absenceStatus !== 'Request',
          };
        return appointmentWol;
      }
    );
  }

  /**
   * Gets cell color from ta
   *
   * @param date
   * @param currentView
   * @param ws Work Schedule
   * @param bs Break Schedule
   * @param vs Vacation Schedl
   * @param hs Holiday Schedule
   * @returns cell color from ta
   */
  getCellColorFromTA(
    date: Date,
    currentView: string,
    ws: ScheduleDetail[],
    bs: ScheduleDetail[],
    vs: ScheduleDetail[],
    hs: ScheduleDetail[]
  ): { special: boolean; color: string } {
    const isHoliday =
      currentView !== 'Month'
        ? hs.some(
            (es) =>
              date.getTime() >= es.scheduledStartDateTime!.getTime() &&
              date.getTime() < es.scheduledEndDateTime!.getTime()
          )
        : hs.some(
            (es) =>
              date.getTime() >=
                startOfDay(es.scheduledStartDateTime!).getTime() &&
              date.getTime() < endOfDay(es.scheduledEndDateTime!).getTime()
          );
    if (isHoliday) return { special: true, color: '#cae8ca' };

    const isVacation = vs.some(
      (es) =>
        date.getTime() >= es.scheduledStartDateTime!.getTime() &&
        date.getTime() < es.scheduledEndDateTime!.getTime()
    );
    if (isVacation) return { special: true, color: '#c9c9ffd9' };

    const isUnavailable = bs.some(
      (es) =>
        date.getTime() >= es.scheduledStartDateTime!.getTime() &&
        date.getTime() < es.scheduledEndDateTime!.getTime()
    );
    if (isUnavailable)
      return { special: true, color: 'rgba(255, 193, 7, 0.2)' };

    const isNotWorking =
      currentView === 'Month'
        ? !ws.some(
            (es) =>
              date.getTime() === startOfDay(es.scheduledStartDateTime).getTime()
          )
        : !ws.some(
            (es) =>
              date.getTime() >= es.scheduledStartDateTime!.getTime() &&
              date.getTime() < es.scheduledEndDateTime!.getTime()
          );
    if (isNotWorking) return { special: true, color: 'rgb(195 195 195 / 20%)' };

    return { special: false, color: 'unset' };
  }
}
